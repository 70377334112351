<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/liveManage"
            >直播管理 /
          </router-link>
          <span class="crumbs_item crumbs_last">{{
            operation == 1 ? "新建" : operation == 2 ? "编辑" : "详情"
          }}</span>
        </span>
      </div>
    </div>

    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name all_required">直播名称：</span>
        <div class="right_Div">
          <a-input
            class="all_input"
            v-model="formObj.title"
            placeholder="请输入直播名称"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">主讲老师：</span>
        <div class="right_Div">
          <a-input
            class="all_input"
            v-model="formObj.teacherName"
            placeholder="请输入主讲老师"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name">简介：</span>
        <div class="right_Div">
          <a-textarea
            v-model="formObj.summary"
            class="all_input"
            placeholder="请输入简介"
            :maxLength="255"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            style="resize: none"
          />
        </div>
      </div>

      <a-row>
        <a-col :span="8">
          <div class="L_R_inner">
            <span class="all_left_name all_required"
              >封面：
              <div class="all_left_name redtext">建议尺寸：630*300px</div>
            </span>
            <!-- <span class="all_left_name all_required">移动端、pc封面：</span> -->
            <div class="right_Div">
              <a-upload
                class="uploader-image"
                list-type="picture-card"
                :showUploadList="false"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
                :beforeUpload="beforeUpload"
                :customRequest="customRequest"
              >
                <img
                  v-if="formObj.cover"
                  :src="formObj.cover"
                  style="max-width: 180px"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="L_R_inner">
            <span class="all_left_name all_required"
              >1:1封面：
              <div class="all_left_name redtext">建议尺寸：100*100px</div>
            </span>
            <!-- <span class="all_left_name all_required">移动端、pc封面：</span> -->
            <div class="right_Div">
              <a-upload
                class="uploader-image"
                list-type="picture-card"
                :showUploadList="false"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
                :beforeUpload="beforeUpload"
                :customRequest="coverSquarecustomRequest"
              >
                <img
                  v-if="formObj.coverSquare"
                  :src="formObj.coverSquare"
                  style="max-width: 180px"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-col>
      </a-row>

      <div class="L_R_inner">
        <div class="Up_Down_inner">
          <span class="all_left_name all_required">详情介绍：</span>
          <UE
            editorId="introduceEditor1"
            :initContent="formObj.content"
            @input="getContent"
          />
        </div>
      </div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">直播时间：</span>
        <div class="right_Div">
          <!-- <a-range-picker
            v-model="liveTime"
            :show-time="{ format: 'HH:mm' }"
            valueFormat="YYYY-MM-DD HH:mm"
            format="YYYY-MM-DD HH:mm"
            :placeholder="['开始时间', '结束时间']"
            @ok="onLiveTime"
          /> -->
          <a-date-picker
            v-model="onceliveTime"
            valueFormat="YYYY-MM-DD"
            placeholder="请选择直播日期"
          />
          <a-time-picker
            v-model="startTime"
            valueFormat="HH:mm:ss"
            placeholder="直播开始时间"
          />
          <a-time-picker
            v-model="endTime"
            valueFormat="HH:mm:ss"
            placeholder="直播结束时间"
          />
        </div>
      </div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">预约时间：</span>
        <div class="right_Div">
          <a-range-picker
            v-model="preBookTime"
            :show-time="{ format: 'HH:mm' }"
            valueFormat="YYYY-MM-DD HH:mm"
            format="YYYY-MM-DD HH:mm"
            :placeholder="['开始时间', '结束时间']"
            @change="onPreBookTime"
            @ok="onPreBookTime"
          />
        </div>
      </div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">第三方直播链接：</span>
        <div class="right_Div">
          <a-input
            class="all_input"
            v-model="formObj.liveUrl"
            placeholder="请输入第三方直播链接"
          />
        </div>
      </div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">提醒方式:</span>
        <div class="right_Div">
          <a-radio-group v-model="formObj.remindType">
            <a-radio :value="2">手动</a-radio>
            <a-radio :value="1">自动</a-radio>
            开播前
            <a-input-number
              id="inputNumber"
              :disabled="formObj.remindType == 2"
              v-model="remindTime"
              :min="1"
            />
            分钟触发
          </a-radio-group>
        </div>
      </div>
     

      <div class="L_R_inner">
        <span class="all_left_name all_required">微信提醒文案：</span>
        <div class="right_Div">
          <a-textarea
            v-model="formObj.wxNote"
            class="all_input"
            placeholder="请输入微信提醒文案"
            :maxLength="255"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            style="resize: none"
          />
        </div>
      </div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">预约人群：</span>
        <div class="right_Div">
          <a-radio-group
            v-model="formObj.relevancyCourse"
            @change="changeRelevancyCourse"
          >
            <a-radio :value="0"> 所有人可预约 </a-radio>
            <a-radio :value="1">
              仅购买关联课程学员可预约
              <a-select
                @change="selectCourse"
                v-if="formObj.relevancyCourse == 1"
                v-model="formObj.courseId"
                placeholder="请选择课程"
              >
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option
                  :value="item.courseId"
                  v-for="(item, index) in courseList"
                  :key="index"
                >
                  {{ item.courseName }} -
                  {{ item.status == 2 ? "已上架" : "未上架" }}
                </a-select-option>
              </a-select>
            </a-radio>
          </a-radio-group>
        </div>
      </div>
       <div class="L_R_inner">
        <span class="all_left_name all_required">上架时间:</span>
        <div class="right_Div">
          <a-radio-group v-model="formObj.shelvesType">
            <a-radio :value="1">立即上架</a-radio>
            <a-radio :value="0">暂不上架</a-radio>
            <a-radio :value="2">设置上架时间</a-radio>
            <a-date-picker
              :disabled="formObj.shelvesType != 2"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              v-model="formObj.shelvesTime"
              placeholder="请设置上架时间"
            />
          </a-radio-group>
        </div>
      </div>
    </div>
    <div class="button">
      <a-button
        type="primary"
        class="btn"
        v-hasPermi="['live:info:edit']"
        v-if="operation != 3"
        :loading="btnLoading"
        @click="submit"
        >确认</a-button
      >
      <a-button class="all_boder_btn btn" @click="$router.go(-1)">{{
        operation != 3 ? "取消" : "返回"
      }}</a-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import UE from "@/components/UE.vue";
export default {
  // 可用组件的哈希表
  components: {
    UE,
  }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      loading: false,
      btnLoading: false,
      operation: null, // 1-新建 2-编辑 3-详情 4-预约名单
      courseList: [], // 课程列表
      formObj: {
        content: null, //直播详情
        cover: null, //封面
        coverSquare: null, // 方形封面
        id: null, //主键
        liveEndDate: null, //直播结束时间
        liveStartDate: null, //直播开始时间
        liveUrl: "", //直播链接
        remindType: 2, //提醒类型 1-定时触发 2- 人工触发
        remindTime: null, //提醒时间
        subscribeEndDate: null, //预约结束时间
        subscribeStartDate: null, //预约开始时间
        summary: "", //直播简介
        teacherName: "", //主讲教师
        title: "", //直播标题
        wxNote: "", //微信提醒文案
        relevancyCourse: 0, // 是否关联课程
        courseId: undefined,
        courseName: "",
      },
      liveTime: ["", ""], // 直播时间
      preBookTime: ["", ""], // 预约时间
      onceliveTime: "",
      startTime: "",
      remindTime: null, //提醒时间
      endTime: "",

      shelvesType: "", //0未上架，1立即上架，2定时上架
      shelvesTime: "", //上架时间
    };
  }, // 事件处理器
  methods: {
    moment,
    selectCourse(e) {
      let arr = this.courseList.filter((item) => {
        return item.courseId == e;
      });
      if (arr.length) {
        this.formObj.courseName = arr[0].courseName;
      }
    },
    changeRelevancyCourse(e) {
      console.log(e);
      this.formObj.courseId = undefined;
      this.formObj.courseName = "";
    },
    // 获取课程列表
    getCourseList() {
      this.$ajax({
        url: "/hxclass-management/course/select",
        method: "get",
        params: {
          flag: 1,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.courseList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 上传前钩子上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 文件上传
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.formObj.cover = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },
    // 方形
    coverSquarecustomRequest(fileData) {
      console.log(fileData)
      this.loading = true;

      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.formObj.coverSquare = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 富文本内容有变化时触发
    getContent(e) {
      this.$set(this.formObj, "content", e);
    },

    // 直播时间
    onLiveTime() {
      this.formObj.liveStartDate = this.liveTime[0];
      this.formObj.liveEndDate = this.liveTime[1];
    },

    // 预约时间
    onPreBookTime() {
      this.formObj.subscribeStartDate = this.preBookTime[0];
      this.formObj.subscribeEndDate = this.preBookTime[1];
    },

    // 获取直播详情
    getLiveDetail() {
      this.$ajax({
        url: "/hxclass-management/liveInfo/getLiveInfoDetail",
        params: {
          id: this.formObj.id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.formObj = res.data;
          this.onceliveTime = res.data.liveStartDate.split(" ")[0];
          this.startTime = res.data.liveStartDate.split(" ")[1];
          this.endTime = res.data.liveEndDate.split(" ")[1];
          let tattime =
            new Date(res.data.liveStartDate).getTime() -
            new Date(res.data.remindTime).getTime();
          this.remindTime = parseInt(tattime / 60000);
          this.liveTime = [res.data.liveStartDate, res.data.liveEndDate];
          this.preBookTime = [
            res.data.subscribeStartDate,
            res.data.subscribeEndDate,
          ];
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 提交
    submit() {
      if (!this.formObj.title) {
        return this.$message.warn("请输入直播名称！");
      } else if (!this.formObj.teacherName) {
        return this.$message.warn("请输入主讲老师！");
      } else if (!this.formObj.cover) {
        return this.$message.warn("请上传直播封面！");
      } else if (!this.formObj.coverSquare) {
        return this.$message.warn("请上传直播1:1封面！");
      } else if (!this.formObj.content) {
        return this.$message.warn("请输入详情介绍！");
      } else if (!this.onceliveTime || !this.startTime || !this.endTime) {
        return this.$message.warn("请选择直播时间！");
      }

      if (!this.formObj.subscribeStartDate) {
        return this.$message.warn("请选择预约时间！");
      }

      // 毫秒化比较
      let start = Date.parse("1970-01-01 " + this.startTime);
      let end = Date.parse("1970-01-01 " + this.endTime);
      if (start > end) {
        return this.$message.warn("直播开始时间不能大于直播结束时间");
      }

      // 时间没问题-拼接
      this.formObj.liveStartDate = this.onceliveTime + " " + this.startTime;
      this.formObj.liveEndDate = this.onceliveTime + " " + this.endTime;
      let beforeOneHour = new Date(
        new Date(this.formObj.liveStartDate).getTime() -
          this.remindTime * 60 * 1000
      );
      var time1 = beforeOneHour.getTime();
      // console.log(this.timestampToTime(time1));
      this.formObj.remindTime = this.timestampToTime(time1);
      let liveStart = new Date(this.formObj.liveStartDate).getTime();
      let subscribeStart = new Date(this.formObj.subscribeEndDate).getTime();
      if (liveStart < subscribeStart) {
        return this.$message.warn("预约时间不可晚于直播时间");
      }

      if (!this.formObj.liveUrl) {
        return this.$message.warn("请输入直播链接！");
      } else if (!this.formObj.wxNote) {
        return this.$message.warn("请输入微信提醒文案！");
      } else if (this.formObj.relevancyCourse) {
        if (!this.formObj.courseId) {
          return this.$message.warn("请选择绑定课程！");
        }
      }

      this.btnLoading = true;
      this.$ajax({
        url: "/hxclass-management/liveInfo/saveOrUpdateLiveInfo",
        method: "post",
        params: this.formObj,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$router.go(-1);
        } else {
          this.btnLoading = false;
          this.$message.error(res.message);
        }
      });
    },

    // 返回格式化时间
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 返回格式化时间
      return Y + M + D + h + m + s;
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    if (this.$route.query.operation) {
      this.operation = this.$route.query.operation;
    }
    if (this.$route.query.id) {
      this.formObj.id = this.$route.query.id;
      this.getLiveDetail();
    }
    this.getCourseList();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }

  .redtext {
    font-size: 12px;
    color: red;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 23px;
  }
  .align {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff0000;
    line-height: 16px;
    margin-left: 190px;
  }

  .all_content_box {
    .L_R_inner {
      margin: 24px;
      .all_left_name {
        width: 180px;
        text-align: right;
      }

      .else_input {
        margin-right: 12px;
        width: 140px;
      }

      .vue-ueditor-wrap[data-v-1e1388a0] {
        margin-left: 140px;
        width: 600px;
      }

      .right_Div {
        .ant-calendar-picker,
        .ant-select,
        .all_input {
          width: 337px;
        }
        /deep/.ant-input,
        /deep/.ant-time-picker-input {
          height: 37px;
        }
        .ant-time-picker {
          margin-left: 20px;
        }
      }
    }
  }

  .button {
    .btn {
      margin-left: 20px;
    }
  }
}
</style>